import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDocumentsList } from './prestacaoDeContasActions';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Link,
  Typography,
} from '@material-ui/core';

const PrestacaoDeContas = ({ getDocumentsList, documentosTransparency }) => {
  useEffect(() => {
    getDocumentsList();
  }, [getDocumentsList]);

  const groupedDocuments = documentosTransparency.reduce((acc, documento) => {
    const year =
      documento.nome.split('-')[1] || documento.nome.split('/')[1] || '';
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(documento);
    return acc;
  }, {});

  return (
    <section id="prestacao-de-contas">
      {documentosTransparency.length === 0 ? (
        <Typography variant="h5" style={{ color: '#838383', width: '100%' }}>
          Não há documentos a serem exibidos.
        </Typography>
      ) : (
        <>
          <Typography variant="h4" className="body-title mt-4">
            Prestação de contas
          </Typography>
          <Typography
            variant="body1"
            style={{ color: '#838383', width: '100%' }}
          >
            O Sindilegis recuperou sua credibilidade junto aos servidores do
            Congresso Nacional e TCU graças à seriedade com que trata o
            patrimônio dos filiados. Acreditamos que a transparência é
            fundamental em qualquer gestão. Por isso, todas as prestações de
            contas a partir de 2014, analisadas pelo Conselho Fiscal,
            encontram-se disponíveis abaixo.
          </Typography>
          <List subheader={<li />}>
            {Object.keys(groupedDocuments)
              .sort((a, b) => b - a)
              .map((year) => (
                <li key={`section-${year}`}>
                  <ListSubheader>
                    <strong>Ano de {year}</strong>
                  </ListSubheader>
                  {groupedDocuments[year].map((documento) => (
                    <ListItem key={`${year}-${documento.nome}`}>
                      <ListItemText
                        primary={
                          <Link
                            href={documento.anexo?.url}
                            target="_blank"
                            rel="noopener"
                          >
                            {documento.nome}
                          </Link>
                        }
                      />
                    </ListItem>
                  ))}
                </li>
              ))}
          </List>
        </>
      )}
    </section>
  );
};

const mapStateToProps = (state) => ({
  documentosTransparency: state.prestacaoDeContas.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getDocumentsList }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PrestacaoDeContas);
